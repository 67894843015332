import Axios from 'axios';
import qs from 'qs';
import Cookies from 'js-cookie';

import {
  SUCCESS,
  FAIL,
  LOGOUT,
  START_SETTINGS_LOAD,
  LOAD_SETTINGS_DATA,
  CHANGE_SMS_SETTING,
  SAVE_SETTINGS_DATA,
  POPUP_OPEN,
  POPUP_DELETE_PROFILE,
  DELETE_PROFILE, POPUP_CLOSE,
  POPUP_SUCCESS_DELETE,
} from '../constants';
import { api } from '../util';

export function logout() {
  return (dispatch) => {
    Axios
      .get(api.logout, { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        Cookies.remove('SID');

        dispatch({
          type: LOGOUT + SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: LOGOUT + FAIL,
          payload: err,
        });
      });
  };
}

export function loadSettingsData() {
  return (dispatch) => {
    dispatch({
      type: START_SETTINGS_LOAD,
    });

    Axios.get(api.ownEdit, { withCredentials: true })
      .then((res) => {
        if (res.status >= 400) throw new Error(res);
        return res.data;
      })
      .then((data) => {
        dispatch({
          type: LOAD_SETTINGS_DATA + SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        console.error('---', error);
        dispatch({
          type: LOAD_SETTINGS_DATA + FAIL,
          payload: { error },
        });
      });
  };
}

export function changeSmsSetting(evt) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_SMS_SETTING,
      payload: evt.target.value,
    });
  };
}

export function saveSettingsData() {
  return (dispatch, getState) => {
    const { settings: { savingData } } = getState();
    const url = `${api.dataSave}`;

    dispatch({
      type: START_SETTINGS_LOAD,
    });

    Axios
      .post(url, qs.stringify({
        DatingUserFormData: savingData,
      }), { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        dispatch({
          type: SAVE_SETTINGS_DATA + SUCCESS,
          payload: data,
        });
        dispatch({
          type: POPUP_OPEN,
          payload: {
            popupTitle: 'popup_success_save_settings_title',
            popupMessage: 'popup_success_save_settings_message',
          },
        });
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: SAVE_SETTINGS_DATA + FAIL,
          payload: err,
        });
      });
  };
}

export function openDeletePopup() {
  return (dispatch) => {
    dispatch({
      type: POPUP_DELETE_PROFILE,
    });

    dispatch({
      type: POPUP_OPEN,
      payload: {
        popupTitle: 'popup_delete_title',
        popupMessage: 'popup_delete_message',
      },
    });
  };
}

export function deleteProfile() {
  return (dispatch) => {
    const url = `${api.deleteProfile}`;

    dispatch({
      type: START_SETTINGS_LOAD,
    });

    Axios
      .get(url, { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        dispatch({
          type: DELETE_PROFILE + SUCCESS,
          payload: data,
        });

        // по каким то неведомым причинам попап сразу закрывается
        dispatch({
          type: POPUP_SUCCESS_DELETE,
        });
        dispatch({
          type: POPUP_OPEN,
          payload: {
            popupTitle: 'Анкета удалена',
            popupMessage: 'Ваша Анкета удалена',
          },
        });
        dispatch({
          type: POPUP_CLOSE,
          payload: data,
        });
      })
      .then(() => {
        Axios
          .get(api.logout, { withCredentials: true })
          .then(res => res.data)
          .then((data) => {
            Cookies.remove('SID');


            dispatch({
              type: LOGOUT + SUCCESS,
              payload: data,
            });
          })
          .catch((err) => {
            console.error('---', err);
            dispatch({
              type: LOGOUT + FAIL,
              payload: err,
            });
          });
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: DELETE_PROFILE + FAIL,
          payload: err,
        });
        dispatch({
          type: POPUP_CLOSE,
          payload: err,
        });
      });
  };
}

const generateData = () => {
  const months = [
    'month_january',
    'month_february',
    'month_march',
    'month_april',
    'month_may',
    'month_june',
    'month_july',
    'month_august',
    'month_september',
    'month_october',
    'month_november',
    'month_december',
  ];

  const dates = [];

  const years = [];

  const exports = {};

  Object.defineProperties(exports, {
    months: {
      get() { return months; },
      numerable: true,
    },
    dates: {
      get() {
        if (dates.length > 0) return dates;

        for (let i = 1; i <= 31; i++) {
          dates.push(i);
        }

        return dates;
      },
      numerable: true,
    },
    years: {
      value(from = 18, to = 98) {
        if (years.length > 0) return years;

        const date = new Date();
        const currentYear = date.getFullYear();
        const maxYear = currentYear - from;
        const minYear = currentYear - to;

        for (let i = minYear; i <= maxYear; i++) {
          years.push(i);
        }

        return years;
      },
      enumerable: true,
    },
  });

  return exports;
};

// generateData.$inject = [];

export default generateData;

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import axios from 'axios';
import './Oferta.scss';
import api from '../../util/api';

class Oferta extends React.Component {
  state = {
    contentRu: {},
    contentUz: {},
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    try {
      axios.get(api.ofertaRu)
        .then((res) => {
          this.setState({
            contentRu: res.data,
          });
        });
    } catch (e) {
      console.error('---', e);
    }

    try {
      axios.get(api.ofertaUz)
        .then((res) => {
          this.setState({
            contentUz: res.data,
          });
        });
    } catch (e) {
      console.error('---', e);
    }
  };

  render() {
    const { contentRu, contentUz } = this.state;
    const { language } = this.props.language;

    return (
      Object.keys(contentRu).length || Object.keys(contentUz).length
        // eslint-disable-next-line no-underscore-dangle
        ? <div dangerouslySetInnerHTML={language.language === 'ru' ? { __html: contentRu } : { __html: contentUz }} />
        : null
    );
  }
}

const mapStateToProps = ({ language }) => ({ language });

export default withRouter(connect(mapStateToProps)(Oferta));

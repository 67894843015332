import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router';
import { debounce } from 'throttle-debounce';
import { Link } from 'react-router-dom';
import ProgressiveImage from 'react-progressive-image';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { AGE_FROM, AGE_TO, CATEGORIES } from '../../constants';
import { stateToCity } from '../../util/dictionares';

import Loader from '../Loader/Loader';
import SimilarCityList from '../SimilarCityList';
import CheckboxFields from '../CheckboxFields';
import PhotoIcon from '../Icons/PhotosIcon';

import './Profile.scss';

import setLanguage from '../../util/setLanguage';
import generateData from '../../util/generateData';
import generateOptions from '../ProfileDateOptions';
import Checkboxes from '../Checkboxes';
import {
  loadProfileInfo,
  loadCityList,
  formChangeHandler,
  cityChange,
  nameChange,
  selectChange,
  openCityList,
  submitSettings,
  resetProfileError,
} from '../../actions/profile';

class Profile extends Component {
  static propTypes = {
    auth: PropTypes.shape({
      isLoggedIn: PropTypes.bool,
      isEventStatusLoad: PropTypes.bool,
    }),

    edit: PropTypes.shape({
      birthday: PropTypes.string,
    }),

    view: PropTypes.shape({
      imgUrl: PropTypes.string,
      name: PropTypes.string,
      age: PropTypes.string,
    }),

    editableFields: PropTypes.shape({
      possibleValues: PropTypes.shape({
        predefined_field_values: PropTypes.shape({
          book: PropTypes.arrayOf(PropTypes.object),
          film: PropTypes.arrayOf(PropTypes.object),
          sport: PropTypes.arrayOf(PropTypes.object),
          hobby: PropTypes.arrayOf(PropTypes.object),
          kitchen: PropTypes.arrayOf(PropTypes.object),
          music: PropTypes.arrayOf(PropTypes.object),
          foreign: PropTypes.arrayOf(PropTypes.object),
        }),
      }),
    }),

    cityList: PropTypes.arrayOf(PropTypes.shape()),
    isEditLoad: PropTypes.bool,
    isViewLoad: PropTypes.bool,
    isEditableFieldsLoad: PropTypes.bool,
    isCityListLoaded: PropTypes.bool,
    isCityListOpen: PropTypes.bool,
    isFormChange: PropTypes.bool,
    isCityIdChange: PropTypes.bool,
    isError: PropTypes.bool,
    savingData: PropTypes.shape(),

    loadProfileInfo: PropTypes.func,
    loadCityList: PropTypes.func,
    formChangeHandler: PropTypes.func,
    cityChange: PropTypes.func,
    nameChange: PropTypes.func,
    selectChange: PropTypes.func,
    openCityList: PropTypes.func,
    submitSettings: PropTypes.func,
    resetProfileError: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.callCityChange = debounce(300, this.callCityChange);
  }

  componentDidMount() {
    this.props.loadProfileInfo();
  }

  componentWillUnmount() {
    this.props.resetProfileError();
  }

  submitHandler = () => {
    // сохраняем данные из полей возраста, так, как если сохранять изменения без них,
    // серверное апи проставляет стандартный возраст
    this.props.selectChange();
    this.props.submitSettings();
  };

  nameInputHandler = (evt) => {
    this.props.nameChange(evt);
  };

  cityChangeHandler(evt) {
    const { value } = evt.target;
    const { id } = evt.target.dataset;
    this.callCityChange(value, id);
    this.props.openCityList();
  }

  callCityChange(value, id) {
    this.props.cityChange(value, id);
    this.props.loadCityList();
  }

  render() {
    const { isLoggedIn, isEventStatusLoad } = this.props.auth;
    const { language } = this.props.language;
    const currentLan = language.language;

    const {
      edit,
      view,
      editableFields,
      isEditLoad,
      isViewLoad,
      isEditableFieldsLoad,
      isCityListLoaded,
      isError,
      cityList,
      isCityIdChange,
      isCityListOpen,
      isFormChange,
      formChangeHandler,
      selectChange,
    } = this.props;

    if (!isEditLoad || !isViewLoad || !isEditableFieldsLoad || !isCityListLoaded || !isEventStatusLoad) {
      return <Loader />;
    }

    if (!isLoggedIn) {
      return <Redirect to="/authentication" />;
    }

    if (isError) {
      return <Redirect to="/" />;
    }

    // Деструктуризируем данные, полученные от api
    const {
      photo_url: imgUrl, name, age, cityName,
    } = view;
    const { birthday } = edit;
    const { city } = this.props.savingData;
    const {
      book, film, sport, hobby, kitchen, music, foreign_lang: foreign,
    } = editableFields.possibleValues.predefined_field_values;

    const cityId = city;

    const { dates, months } = generateData();
    const years = generateData()
      .years(AGE_FROM, AGE_TO);

    // из строки с датой рождения получаем массив и назначаем в отдельные переменные
    const birthdayArr = birthday.split(',')
      .map(string => +string);

    const defaultDate = birthdayArr[2];
    const defaultMonth = months.map(elem => setLanguage(language, elem))[birthdayArr[1] - 1];
    const defaultYear = birthdayArr[0];

    const datesOptions = generateOptions(dates, 'birthday_day');
    const monthsOptions = generateOptions(months.map(elem => setLanguage(language, elem)), 'birthday_month');
    const yearsOptions = generateOptions(years, 'birthday_year');

    const categories = [];
    const fields = [book, film, sport, hobby, kitchen, music, foreign];

    for (let i = 0; i < CATEGORIES.length; i++) {
      categories.push(
        {
          category: CATEGORIES[i],
          field: Checkboxes(fields[i]),
        },
      );
    }

    const placeholder = (
      <div className="profile__user-img-placeholder" />
    );

    return (
      <div className="profile">

        <section className="profile__user-avatar">
          <ProgressiveImage className="profile__user-img" src={imgUrl} alt={name} placeholder="">
            {/* eslint-disable-next-line */}
            {(src, loading) => {
              return loading ? placeholder : <img className="profile__user-img" src={src} alt="Пользователь" />;
            }}
          </ProgressiveImage>
          <Link to="/photos" className="profile__user-photo-icon">
            <PhotoIcon />
          </Link>
        </section>

        <section className="profile__user-info">
          <p className="profile__user-name">
            {name}
            ,
            &nbsp;
            {age}
          </p>
          <p className="profile__user-city">
            {(currentLan === 'ru') ? _.invert(stateToCity)[cityName] || cityName : stateToCity[cityName] || cityName}
          </p>
        </section>

        <form className="profile__form form" method="post" onChange={formChangeHandler}>

          <fieldset className="form__fieldset">
            <label className="form__label" htmlFor="name">
              {setLanguage(language, 'word_name')}
            </label>
            <input className="form__input" type="text" name="name" defaultValue={name} onChange={this.nameInputHandler} />

            <label className="form__label" htmlFor="age">
              {setLanguage(language, 'word_age')}
            </label>
            <div className="form__age-box">
              <div className="select__wrapper">
                <select
                  className="form__select" name="age" defaultValue={defaultDate}
                  onChange={selectChange} data-name="birthday_day"
                >
                  {datesOptions}
                </select>
              </div>
              <div className="select__wrapper">
                <select
                  className="form__select" defaultValue={defaultMonth}
                  onChange={selectChange} data-name="birthday_month"
                >
                  {monthsOptions}
                </select>
              </div>
              <div className="select__wrapper">
                <select
                  className="form__select" defaultValue={defaultYear}
                  onChange={selectChange} data-name="birthday_year"
                >
                  {yearsOptions}
                </select>
              </div>
            </div>

            <label className="form__label" htmlFor="city">
              {setLanguage(language, 'word_city')}
            </label>
            <div className="form__wrapper">
              <input
                className="form__input city"
                type="text"
                autoComplete="off"
                spellCheck="off"
                name="city"
                data-id={cityId}
                placeholder={setLanguage(language, 'reg_page_city_placeholder')}
                defaultValue={cityName}
                onChange={this.cityChangeHandler.bind(this)}
              />
              {isCityListOpen && (
                <SimilarCityList cityList={cityList} />
              )}
              {!isCityIdChange && (
                <p className="profile__error">{setLanguage(language, 'reg_page_city_error')}</p>
              )}

            </div>
          </fieldset>

          <CheckboxFields categories={categories} />

          {(isFormChange && isCityIdChange) && (
            <button type="button" className="profile__user-save-button button button--red" onClick={this.submitHandler}>
              {setLanguage(language, 'profile_save_button')}
            </button>
          )}
        </form>

      </div>
    );
  }
}

function mapStateToProps({
  auth,
  language,
  profile:
 {
   edit,
   view,
   editableFields,
   cityList,
   cityName,
   isEditLoad,
   isViewLoad,
   isEditableFieldsLoad,
   isCityListLoaded,
   isCityListOpen,
   isFormChange,
   isCityIdChange,
   isError,
   savingData,
 },
}) {
  return {
    auth,
    language,
    edit,
    view,
    editableFields,
    cityList,
    cityName,
    isEditLoad,
    isViewLoad,
    isEditableFieldsLoad,
    isCityListLoaded,
    isCityListOpen,
    isFormChange,
    isCityIdChange,
    isError,
    savingData,
  };
}

const mapDispatchToProps = {
  loadProfileInfo,
  loadCityList,
  formChangeHandler,
  cityChange,
  nameChange,
  selectChange,
  openCityList,
  submitSettings,
  resetProfileError,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));

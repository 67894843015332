import Axios from 'axios';
import qs from 'qs';
import Cookies from 'js-cookie';

import {
  SUCCESS,
  FAIL,
  AUTH,
  REDIRECT_TO_AUTH,
  AUTH_INPUT_CHANGE,
  EVT_STATUS,
  RESET_EVT_STATUS,
  RECOVERY_SEND_PHONE,
  START_RECOVERY_SEND,
  DEFAULT_SID,
  SET_FLOW_URL,
} from '../constants';
import { api } from '../util';
import { getSID } from '../util/parseURL';

export function login() {
  return (dispatch) => {
    const COOKIES_DATE = 7;
    const sid = getSID();
    const url = `${api.login}?SID=${sid}`;

    if (sid === DEFAULT_SID) {
      dispatch({
        type: REDIRECT_TO_AUTH,
      });
    } else {
      Cookies.set('SID', sid, { expires: COOKIES_DATE });
    }

    Axios
      .post(url, '', { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        dispatch({
          type: AUTH + SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: AUTH + FAIL,
          payload: err,
        });
      });
  };
}

export function recovery() {
  return (dispatch, getState) => {
    const { auth: { phone } } = getState();

    dispatch({
      type: START_RECOVERY_SEND,
    });

    Axios
      .post(api.recovery, qs.stringify({
        telnum: `${phone.replace(/[^0-9]/g, '')}`,
      }), { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        dispatch({
          type: RECOVERY_SEND_PHONE + SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: RECOVERY_SEND_PHONE + FAIL,
          payload: err,
        });
      });
  };
}

export function checkEvtStatus() {
  return (dispatch) => {
    const COOKIES_DATE = 7;
    const sid = getSID();
    const url = `${api.evtStatus}?SID=${sid}`;

    if (sid === DEFAULT_SID) {
      dispatch({
        type: REDIRECT_TO_AUTH,
      });
    } else {
      Cookies.set('SID', sid, { expires: COOKIES_DATE });
    }

    Axios
      .get(url, { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        dispatch({
          type: EVT_STATUS + SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: EVT_STATUS + FAIL,
          payload: err,
        });
      });
  };
}

export function resetEvtStatus() {
  return ({
    type: RESET_EVT_STATUS,
  });
}

export function inputChange(evt) {
  return ({
    type: AUTH_INPUT_CHANGE,
    payload: {
      value: evt.target.value,
      fieldName: evt.target.dataset.name,
    },
  });
}

export function fetchFlowURL() {
  return (dispatch) => {
    Axios.get(api.getCaptchaKey, { withCredentials: true })
      .then((response) => {
        dispatch({ type: SET_FLOW_URL, url: response.data.flow_url });
      });
  };
}

import {
  POPUP_OPEN,
} from '../constants';

function openMainPopup() {
  return ({
    type: POPUP_OPEN,
    payload: {
      popupTitle: 'popup_main_title',
      popupMessage: 'popup_main_message',
    },
  });
}

export default openMainPopup;

import React from 'react';

function MessageIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140">
      <circle fill="#fff" cx="70" cy="70" r="70" />
      <g>
        <path fill="#e30611" d="M620,5597.82a5.385,5.385,0,1,0,5.454,5.39A5.417,5.417,0,0,0,620,5597.82Zm23.636,5.39a5.455,5.455,0,1,0-5.454,5.38A5.417,5.417,0,0,0,643.636,5603.21Zm-36.363,0a5.455,5.455,0,1,0-5.455,5.38A5.417,5.417,0,0,0,607.273,5603.21Zm43.146-21.88c5.98,5.65,9.581,12.97,9.581,20.98,0,17.05-16.364,31.01-37.092,32.22-0.962.05-1.926,0.09-2.908,0.09a48.8,48.8,0,0,1-12.727-1.8s-6.873,7.23-20,7.18a16.668,16.668,0,0,0,7.272-12.56c-8.89-5.93-14.545-15.1-14.545-25.13,0-17.84,17.908-32.31,40-32.31C632.182,5570,643.074,5574.4,650.419,5581.33Z" transform="translate(-550 -5533)" />
        <path fill="#fff" d="M588.889,5595.41c-1.229,0-2.478-1.02-2.223-2.22,2-9.4,10.978-14.87,18.556-16.53,1.2-.27,2.222.99,2.222,2.21s-1.058,1.83-2.222,2.22c-4.959,1.67-11.444,6.31-14.111,12.1C590.6,5594.3,590.117,5595.41,588.889,5595.41Z" transform="translate(-550 -5533)" />
      </g>
    </svg>
  );
}

export default MessageIcon;

import Axios from 'axios/index';
import qs from 'qs';
import { api, loadInfo } from '../util';
import {
  SUCCESS,
  FAIL,
  HELP_SEND,
  START_HELP_LOAD,
  POPUP_OPEN,
  HELP_INPUT_CHANGE,
  GET_CAPTCHA,
  NOT_VALID_NUMBER,
  RESET_HELP_PAGE,
  CHANGE_CAPTCHA,
} from '../constants';

export function getCaptcha() {
  return (dispatch) => {
    const url = api.captcha;

    dispatch({
      type: START_HELP_LOAD,
    });

    loadInfo(url, dispatch, {
      onSuccess: GET_CAPTCHA + SUCCESS,
      onError: GET_CAPTCHA + FAIL,
    });
  };
}

export function sendHelp() {
  return (dispatch, getState) => {
    const {
      help: {
        name,
        phone,
        email,
        msg,
        captcha,
      },
    } = getState();

    if (phone.indexOf('(998)97') === -1) {
      dispatch({
        type: NOT_VALID_NUMBER,
      });
      return;
    }

    dispatch({
      type: START_HELP_LOAD,
    });

    Axios
      .post(api.help, qs.stringify({
        responsetype: 'json',
        name_help: name,
        telnum_help: `${phone.replace(/[^0-9]/g, '')}`,
        email_help: email,
        telnum: phone,
        text_help: msg,
        captcha_code: captcha,
      }), { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        dispatch({
          type: HELP_SEND + SUCCESS,
          payload: data,
        });
        if (data.errors.length === 0) {
          dispatch({
            type: POPUP_OPEN,
            payload: {
              popupTitle: 'popup_help_send_success_title',
              popupMessage: 'popup_help_send_success_message',
            },
          });
          dispatch({
            type: RESET_HELP_PAGE,
          });
          dispatch(getCaptcha());
        }
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: HELP_SEND + FAIL,
          payload: err,
        });
      });
    // window.location.reload();
  };
}

export function inputChange(evt) {
  return ({
    type: HELP_INPUT_CHANGE,
    payload: {
      value: evt.target.value,
      fieldName: evt.target.dataset.name,
    },
  });
}

export function changeCaptcha(value) {
  return ({
    type: CHANGE_CAPTCHA,
    payload: value,
  });
}

import {
  SUCCESS,
  FAIL,
  LOAD_VIEW_SHORT,
  LOAD_PHOTO_LIST,
  RESET_PHOTO_PAGE,
  CHANGE_ACTIVE_PHOTO,
  SAVE_MAIN_PHOTO,
  DELETE_PHOTO,
  RESET_PHOTO_LIST,
} from '../constants';

const initialState = {
  isViewShortLoad: false,
  isPhotoListLoad: false,
  viewShort: {},
  photoList: {},
  isError: false,
  currentPage: 1,
  photoCount: null,
  activePhoto: null,
  mainImgUrl: '',
  fullPhotoList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_VIEW_SHORT + SUCCESS:
      return {
        ...state,
        viewShort: action.payload,
        mainImgUrl: action.payload.photo_url,
        isViewShortLoad: true,
      };

    case LOAD_VIEW_SHORT + FAIL:
      return { ...state, isError: true };

    case LOAD_PHOTO_LIST + SUCCESS:
      if (action.payload.data.errors.length !== 0) {
        return {
          ...state,
          isError: true,
          isPhotoListLoad: true,
        };
      }

      return {
        ...state,
        photoList: action.payload.data,
        fullPhotoList: state.fullPhotoList.concat(action.payload.data.urls_small),
        photoCount: action.payload.data.photo_cnt,
        isPhotoListLoad: true,
        currentPage: action.payload.currentPage,
        activePhoto: (action.payload.data.urls_small[0]) ? action.payload.data.urls_small[0].photo_id : null,
      };

    case LOAD_PHOTO_LIST + FAIL:
      return { ...state, isError: true };

    case RESET_PHOTO_PAGE: {
      return {
        ...state,
        isViewShortLoad: false,
        isPhotoListLoad: false,
        viewShort: {},
        photoList: {},
        isError: false,
        currentPage: 1,
        photoCount: null,
        activePhoto: null,
        mainImgUrl: '',
        fullPhotoList: [],
      };
    }

    case CHANGE_ACTIVE_PHOTO:
      const { currentPhoto, currentPhotoUrl } = action.payload;

      return {
        ...state,
        photoList: {},
        activePhoto: currentPhoto,
        mainImgUrl: currentPhotoUrl,
      };

    case SAVE_MAIN_PHOTO + SUCCESS:
      return {
        ...state,
      };

    case SAVE_MAIN_PHOTO + FAIL:
      return { ...state, isError: true };

    case DELETE_PHOTO + SUCCESS:
      return {
        ...state,
      };

    case DELETE_PHOTO + FAIL:
      return { ...state, isError: true };

    case RESET_PHOTO_LIST:
      return { ...state, photoList: {} };

    default:
      return state;
  }
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';

import '../Authentication/Authentication.scss';
import { api } from '../../util';

import Loader from '../Loader/Loader';
import Popup from '../Popup/Popup';

import {
  sendHelp,
  inputChange,
  getCaptcha,
} from '../../actions/help';
import setLanguage from '../../util/setLanguage';

class Help extends Component {
  static propTypes = {
    help: PropTypes.shape({
      captchaImg: PropTypes.string,
      isHelpLoad: PropTypes.bool,
      message: PropTypes.string,
    }),

    popup: PropTypes.shape({
      isPopupOpen: PropTypes.bool,
    }),

    sendHelp: PropTypes.func,
    inputChange: PropTypes.func,
    getCaptcha: PropTypes.func,
  };

  componentDidMount() {
    this.props.getCaptcha();
  }

  handleSubmit = (evt) => {
    evt.preventDefault();

    this.props.sendHelp();
    this.props.getCaptcha();
  };

  render() {
    const { isLoggedIn } = this.props.auth;
    const { language } = this.props.language;
    const { inputChange } = this.props;
    const { isPopupOpen } = this.props.popup;
    const {
      captchaImg, isHelpLoad, message, name, email, phone, msg, captcha,
    } = this.props.help;

    if (isLoggedIn) return <Redirect to="conversation/Moderator" />;

    return (
      <div className="auth__container auth__container--no-flex">
        {!this.isFromReg && (
          <form className="auth-form" method="post" action={api.login} onSubmit={this.handleSubmit}>
            <div className="auth-form__fieldset">
              <label className="auth-form__label" htmlFor="name">
                {setLanguage(language, 'word_name')}
                <span className="auth-form__label auth-form__label--red"> *</span>
              </label>
              <input
                className="auth-form__input"
                type="text"
                name="name"
                required
                data-name="name"
                onChange={inputChange}
                value={name}
              />

              <label className="auth-form__label" htmlFor="email">
                E-mail
                <span className="auth-form__label auth-form__label--red"> *</span>
              </label>
              <input
                type="email"
                id="email"
                className="auth-form__input"
                data-name="email"
                onChange={inputChange}
                required
                value={email}
              />

              <p className="auth-form__notification">
                {setLanguage(language, 'help_hint')}
              </p>

              <label className="auth-form__label" htmlFor="phone">
                {setLanguage(language, 'word_phone')}
                <span className="auth-form__label auth-form__label--red"> *</span>
              </label>
              <div className="auth-form__phonebox">
                <InputMask
                  pattern="^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$"
                  onChange={inputChange}
                  className="auth-form__input"
                  data-name="phone"
                  mask="(999)999999999"
                  placeholder="(998)9XXXXXXXX"
                  maskChar=""
                  id="phone"
                  required
                  value={phone}
                />
              </div>

              <label className="auth-form__label" htmlFor="msg">
                {setLanguage(language, 'word_message')}
              </label>
              <textarea
                className="auth-form__textarea"
                name="msg"
                id="msg"
                data-name="msg"
                onChange={inputChange}
                maxLength="300"
                required
                value={msg}
              />

              <img className="auth-form__captcha" alt="Введите код с картинки" src={`data:image/gif;base64,${captchaImg}`} />
              <div className="auth-form__input-box">
                <input
                  className="auth-form__input"
                  type="text"
                  name="captcha"
                  required
                  data-name="captcha"
                  placeholder={setLanguage(language, 'help_captcha_placeholder')}
                  onChange={inputChange}
                  value={captcha}
                />
                <p className="auth-form__error-msg">{setLanguage(language, message)}</p>
              </div>

              <button type="submit" className="auth-form__button button button--red">
                {setLanguage(language, 'help_send_button')}
              </button>

              <p className="auth-form__notification">
                {setLanguage(language, 'help_oferta_link_text')}
&nbsp;
                <Link to="/oferta">{setLanguage(language, 'help_oferta_link_word')}</Link>
              </p>
            </div>
          </form>
        )}
        {!isHelpLoad && <Loader />}
        {isPopupOpen && <Popup />}
      </div>
    );
  }
}

const mapStateToProps = ({
  help, popup, language, auth,
}) => ({
  help, popup, language, auth,
});

const mapDispatchToProps = {
  sendHelp,
  inputChange,
  getCaptcha,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Help));

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter, Redirect } from 'react-router';
import { debounce } from 'throttle-debounce';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import _ from 'lodash';

import './Main.scss';

import api from '../../util/api';
import Loader from '../Loader/Loader';
import Popup from '../Popup/Popup';
import Search from '../Search/Search';
import User from '../User/User';

import {
  getNextUser,
  getPrevUser,
  loadUserList,
  imgLoadHandler,
  refreshUser,
  setUserFromSlider,
  sendLike,
  openUserProfilePopup,
  closeUserProfilePopup,
  getUserCurrentPhoto,
} from '../../actions/userList';
import { submitSearch, closeSearch, submitExtSearch } from '../../actions/search';
import setLanguage from '../../util/setLanguage';

import openMainPopup from '../../actions/main';
import NextIcon from '../Icons/NextIcon';
import LikeIcon from '../Icons/LikeIcon';
import RefreshIcon from '../Icons/RefreshIcon';
import MessageIcon from '../Icons/MessageIcon';
import extSearchIcon from '../../images/ext-search-icon.png';
import extSearchIconBlue from '../../images/ext-search-icon-blue.png';

import { extDescMap } from '../../constants';

class Main extends Component {
  constructor(props) {
    super(props);
    this.nextButtonHandler = debounce(300, this.nextButtonHandler);
    this.refreshButtonHandler = debounce(300, this.refreshButtonHandler);
    this.likeButtonHandler = debounce(300, this.likeButtonHandler);
    this.clickTimeout = null;
    this.extTimer = null;
    this.bigSlider = React.createRef();
    this.smallSlider = React.createRef();
    this.curUserSlider = [];
    this.isBigSliderChangeOutside = false;
  }

  state = {
    isExtDescActive: false,
    isOpenExtDescByHand: false,
    wasFirstExtShow: false,
    isDoubleClick: false,
  };

  componentDidMount() {
    this.props.loadUserList();
    this.mounted = false;
  }

  componentWillUnmount() {
    this.mounted = true;
    if (this.extTimer) {
      clearTimeout(this.extTimer);
    }
  }

  componentDidUpdate() {
    if (!!this.props.userList.users.toString() && !this.state.wasFirstExtShow && this.props.search.isExtSearch) {
      this.openExtPopup();

      this.setState({ wasFirstExtShow: true });
    }
  }

  userDoubleClick = () => {
    const { isLoggedIn } = this.props.auth;

    if (!isLoggedIn) return;

    if (this.clickTimeout !== null) {
      // window.location.href = api.authUrl;
      this.setState({ isDoubleClick: true });
      clearTimeout(this.clickTimeout);
      this.clickTimeout = null;
    } else {
      this.clickTimeout = setTimeout(() => {
        clearTimeout(this.clickTimeout);
        this.clickTimeout = null;
      }, 2000);
    }
  };

  openExtPopup = () => {
    if (this.mounted) return;

    this.extTimer = setTimeout(() => {
      if (!this.state.isOpenExtDescByHand) {
        this.setState({ isExtDescActive: true });
      }
    }, 3000);
  };

  closeExtPopup = () => {
    this.setState({ isExtDescActive: false, isOpenExtDescByHand: false });
  };

  nextButtonHandler = () => {
    const { getNextUser } = this.props;

    this.closeExtPopup();
    if (this.extTimer) {
      clearTimeout(this.extTimer);
    }
    this.openExtPopup();

    getNextUser();
    this.moveSlidesToCurrent();
  };

  refreshButtonHandler = () => {
    const { refreshUser } = this.props;

    this.isBigSliderChangeOutside = true;

    refreshUser();
    this.moveSlidesToCurrent();
  };

  likeButtonHandler = () => {
    const { sendLike } = this.props;

    this.isBigSliderChangeOutside = true;

    sendLike();
    this.moveSlidesToCurrent();
  };

  bigSliderChange = () => {
    if (!this.bigSlider.current) return;
    this.closeExtPopup();
    if (this.extTimer) {
      clearTimeout(this.extTimer);
    }
    this.openExtPopup();

    const { refreshUser, getPrevUser } = this.props;

    if (this.isBigSliderChangeOutside) {
      this.isBigSliderChangeOutside = false;
      return;
    }

    const activeSlide = this.bigSlider.current.swiper.activeIndex;
    const prevSlide = this.bigSlider.current.swiper.previousIndex;

    if (activeSlide === prevSlide) {
      return;
    }

    if (activeSlide < prevSlide) {
      getPrevUser();
      this.moveSmallSliderToCurrent();
      return;
    }

    refreshUser();
    this.moveSmallSliderToCurrent();
  };

  moveSlidesToCurrent = () => {
    this.bigSlider.current.swiper.slideTo(this.props.userList.index);
    this.smallSlider.current.swiper.slideTo(this.props.userList.index);
  };

  moveSmallSliderToCurrent = () => {
    this.smallSlider.current.swiper.slideTo(this.props.userList.index);
  };

  sliderItemHandler = (evt) => {
    this.isBigSliderChangeOutside = true;
    if (evt.target.dataset.index) {
      this.props.setUserFromSlider(+evt.target.dataset.index);
      this.moveSlidesToCurrent();
    }
  };

  sliderFindActivePhoto = () => {
    const { submitSearch, submitExtSearch } = this.props;
    const { isSearch, isExtSearch } = this.props.search;
    const mySwiper = document.querySelector('.swiper-container').swiper;

    const usersCount = this.props.userList.users.length;
    const activeSlide = mySwiper.realIndex;

    if (usersCount - activeSlide <= 5) {
      if (isSearch) {
        submitSearch(true);
        return;
      }
      if (isExtSearch) {
        submitExtSearch(true);
        return;
      }
      this.props.loadUserList(true);
    }
  };

  extDescButtonHandler = () => {
    this.setState(prevState => ({ isExtDescActive: !prevState.isExtDescActive, isOpenExtDescByHand: true }));
  };

  userPhotosChangeHandler = () => {
    const {
      getUserCurrentPhoto,
    } = this.props;

    const { users, index } = this.props.userList;

    const { nick } = users[index];

    const activeSlide = this.curUserSlider[nick].swiper.activeIndex;
    const prevSlide = this.curUserSlider[nick].swiper.previousIndex;

    if (activeSlide === prevSlide) {
      return;
    }

    getUserCurrentPhoto(activeSlide);
  };

  renderPhotos = users => _.map(users, (user, i) => {
    const { index } = this.props.userList;

    return (
      <div className={(i === index) ? 'main__slider-item main__slider-item--active' : 'main__slider-item '} key={user.nick} data-index={i}>
        <img className="main__slider-photo" src={user.iurl_200} alt={user.name} data-index={i} />
      </div>
    );
  });

  renderCurUserPhotos = photos => _.map(photos, (photo, i) => {
    const {
      imgLoadHandler,
    } = this.props;

    return (
      <div className="main__slider-item" key={photo.photo_id} data-index={i}>
        <div style={{ backgroundImage: `url("${photo.url}")` }}>
          <img className="main__user--img swiper-lazy" src={photo.url} alt="" data-index={i} onLoad={imgLoadHandler} />
          <div className="swiper-lazy-preloader" />
        </div>
      </div>
    );
  });

  renderBigPhotos = users => _.map(users, (user) => {
    const curUserSliderParams = {
      effect: 'fade',
      slidesPerView: 1,
      initialSlide: 0,
      watchOverflow: true,
      preloadImages: false,
      lazy: true,
      watchSlidesVisibility: true,
      // allowTouchMove: false,
      // noSwiping: true,
      nested: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      on: {
        'init': () => false,
        'slideChange': () => this.userPhotosChangeHandler(),
      },
    };

    const userPhotos = this.renderCurUserPhotos(user.photos);

    return (
      <div className="main__img-container" key={user.nick}>
        <Swiper {...curUserSliderParams} ref={(ref) => { this.curUserSlider[user.nick] = ref; return true; }}>
          {userPhotos}
        </Swiper>
      </div>
    );
  });

  renderContent() {
    const {
      searchCnt, isSearchOpen, isExtSearch, isSearchLoad, extSearchType,
    } = this.props.search;
    const { currentCount, isUserProfilePopupOpened } = this.props.userList;
    const { language } = this.props.language;

    if (currentCount > searchCnt || this.props.userList.users.length === 0) {
      return (
        <div className="main">
          {isSearchOpen && <Search />}
          <p className="main__no-user">
            {setLanguage(language, 'main_no_more_users_alert')}
          </p>
        </div>
      );
    }

    const { isLoggedIn, notFullReg, notHaveSub, flowUrl } = this.props.auth;

    if (notFullReg && !notHaveSub) return <Redirect to="registration" />;

    const { isPopupOpen } = this.props.popup;

    const {
      users, index, isFirstLoad, isLoad,
    } = this.props.userList;

    const {
      name, age, city, online, nick, greeting, photos,
    } = users[index];
    const curPhotoIndex = (this.curUserSlider[nick]) ? this.curUserSlider[nick].swiper.activeIndex : 0;
    const numAge = +age.replace(/\D+/g, '');
    const conversationLinkUrl = `/conversation/${nick}`;
    const userLink = `/user/${nick}`;

    if (!isFirstLoad) return <Loader />;

    const sliderParams = {
      slidesPerView: 5,
      initialSlide: index,
      on: {
        'init': () => false,
        'click': evt => this.sliderItemHandler(evt),
        'slideChange': () => this.sliderFindActivePhoto(),
      },
    };

    const bigSliderParams = {
      initialSlide: index,
      slidesPerView: 'auto',
      shortSwipes: false,
      longSwipesRatio: 0.1,
      longSwipesMs: 50,
      on: {
        'init': () => false,
        'slideChange': this.bigSliderChange,
      },
    };

    const sliderPhotos = this.renderPhotos(users);
    const bigSliderPhotos = this.renderBigPhotos(users);

    if (this.state.isDoubleClick) return <Redirect to={userLink} />;

    return (
      <div className="main">
        {(!isLoad || !isSearchLoad) && <Loader />}
        {isSearchOpen && <Search />}

        <div className="main__slider-container">
          <Swiper {...sliderParams} shouldSwiperUpdate ref={this.smallSlider}>
            {sliderPhotos}
          </Swiper>
        </div>

        <section className="main__user--avatar">

          <Swiper {...bigSliderParams} shouldSwiperUpdate ref={this.bigSlider}>
            {bigSliderPhotos}
          </Swiper>

        </section>
        <section className="main__user--info">
          {isExtSearch && (
            <div className="main__user-ext-desc">

              {users[index][extSearchType]
            && (
              <div className={this.state.isExtDescActive ? 'main__user-ext-popup main__user-ext-popup--active' : 'main__user-ext-popup'}>
                <div className="main__user-ext-popup-title">
                  {extDescMap[extSearchType]}
                </div>
                {users[index][extSearchType]}
              </div>
            )
              }

              <button
                className={this.state.isExtDescActive ? 'button main__user-desc-button main__user-desc-button--active' : 'button main__user-desc-button'}
                onClick={this.extDescButtonHandler}
              >
                {this.state.isExtDescActive && <img src={extSearchIcon} alt="" />}
                {!this.state.isExtDescActive && <img src={extSearchIconBlue} alt="" />}
              </button>
            </div>
          )}

          <div className="main__user--info-box" onClick={this.props.openUserProfilePopup}>
            <p className={online ? 'main__user--name main__user--name--online' : 'main__user--name'}>
              {name}
              ,&nbsp;
              {numAge}
            </p>
            <p className="main__user--city">
              {city}
            </p>
          </div>

          {greeting && (
            <div className="main__user-desc" onClick={this.props.openUserProfilePopup}>
              <div className="main__desc-el">
                {greeting}
              </div>
            </div>
          )}

          {isLoggedIn && (
            <div
              className={isUserProfilePopupOpened ? 'main__user--button-box main__user--button-box--fixed' : 'main__user--button-box'}
              onClick={this.props.closeUserProfilePopup}
            >
              <button
                type="button"
                className="main__user--next"
                onClick={this.nextButtonHandler}
              >
                <span className="main__next--icon">
                  <NextIcon />
                </span>
              </button>

              <button
                type="button"
                className="main__user--next"
                onClick={this.refreshButtonHandler}
              >
                <span className="main__refresh--icon">
                  <RefreshIcon />
                </span>
              </button>

              <Link
                to={conversationLinkUrl}
                className="main__user--next"
              >
                <MessageIcon />
              </Link>

              <button
                type="button"
                className="main__user--like"
                onClick={this.likeButtonHandler}
              >
                <LikeIcon />
              </button>
            </div>
          )}

          {!isLoggedIn && (
            <div
              className={isUserProfilePopupOpened ? 'main__user--button-box main__user--button-box--fixed' : 'main__user--button-box'}
              onClick={this.props.closeUserProfilePopup}
            >
              <button
                type="button"
                className="main__user--next"
                onClick={this.nextButtonHandler}
              >
                <span className="main__next--icon">
                  <NextIcon />
                </span>
              </button>

              <button
                type="button"
                className="main__user--next"
                onClick={this.refreshButtonHandler}
              >
                <span className="main__refresh--icon">
                  <RefreshIcon />
                </span>
              </button>

              <a
                className="main__user--next"
                href={flowUrl}
              >
                <MessageIcon />
              </a>

              <a
                className="main__user--like"
                href={flowUrl}
              >
                <LikeIcon />
              </a>
            </div>
          )}

        </section>

        {isPopupOpen && <Popup isFromMain />}

        {isUserProfilePopupOpened && (
          <div className="main__user-profile-popup">
            <User fromMain userId={nick} photos={photos} name={name} age={numAge} city={city} curPhotoIndex={curPhotoIndex} />
          </div>
        )}
      </div>
    );
  }

  render() {
    return this.props.userList.isMainLoad ? this.renderContent() : <Loader />;
  }

  static propTypes = {
    userList: PropTypes.shape({
      currentCount: PropTypes.number,
      users: PropTypes.arrayOf(
        PropTypes.shape({
          iurl_600: PropTypes.string,
          name: PropTypes.string,
          age: PropTypes.string,
          city: PropTypes.string,
        }),
      ),
      index: PropTypes.number,
      isImgLoaded: PropTypes.bool,
      isLoad: PropTypes.bool,
      isMainLoad: PropTypes.bool,
    }).isRequired,

    auth: PropTypes.shape({
      isLoggedIn: PropTypes.bool,
    }),

    popup: PropTypes.shape({
      isPopupOpen: PropTypes.bool,
    }),

    search: PropTypes.shape({
      isSearch: PropTypes.bool,
      searchCnt: PropTypes.number,
      isSearchOpen: PropTypes.bool,
    }),

    index: PropTypes.number,
    isLoad: PropTypes.bool,
    isImgLoaded: PropTypes.bool,
    // isError: PropTypes.bool,

    refreshUser: PropTypes.func,
    loadUserList: PropTypes.func,
    // getNextUser: PropTypes.func,
    imgLoadHandler: PropTypes.func,
    openMainPopup: PropTypes.func,
    submitSearch: PropTypes.func,
    sendLike: PropTypes.func,
    submitExtSearch: PropTypes.func,
  };
}

const mapStateToProps = ({
  userList,
  auth,
  popup,
  search,
  language,
  user,
}) => ({
  userList,
  auth,
  popup,
  search,
  language,
  user,
});

const mapDispatchToProps = {
  loadUserList,
  getNextUser,
  getPrevUser,
  imgLoadHandler,
  openMainPopup,
  submitSearch,
  refreshUser,
  setUserFromSlider,
  closeSearch,
  sendLike,
  submitExtSearch,
  openUserProfilePopup,
  closeUserProfilePopup,
  getUserCurrentPhoto,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));

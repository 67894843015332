import i18next from 'i18next/index';
import Cookies from 'js-cookie';
import Axios from 'axios/index';

import {
  START_LOAD_LANGUAGE,
  CHANGE_LANGUAGE,
  SUCCESS,
  FAIL,
} from '../constants';
import { api } from '../util';

export default function changeLanguage(currentLanguage, needRefresh = false) {
  const language = (currentLanguage === 'ru') ? 'uz' : 'ru';

  i18next.init({
    lng: language,
    resources: require(`../dictionaries/${language}.json`),
  });

  const COOKIES_DATE = 30;
  Cookies.set('lan', language, { expires: COOKIES_DATE });
  Cookies.set('lang', language, { expires: COOKIES_DATE });

  const url = (language === 'ru') ? `${api.setLang}ru/` : `${api.setLang}uz/`;

  return (dispatch) => {
    dispatch({
      type: START_LOAD_LANGUAGE,
      payload: '',
    });
    Axios.get(url, { withCredentials: true })
      .then((res) => {
        if (res.status >= 400) throw new Error(res);
        return res.data;
      })
      .then((data) => {
        dispatch({
          type: CHANGE_LANGUAGE + SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        console.error('---', error);
        dispatch({
          type: CHANGE_LANGUAGE + FAIL,
          payload: { error },
        });
      });

    dispatch({
      type: CHANGE_LANGUAGE,
      payload: {
        language: i18next,
      },
    });
    if (needRefresh) window.location.reload();
  };
}

import React from 'react';

function LikeIcon() {
  return (
    <svg
      enableBackground="new 0 0 500 500"
      id="Layer_1"
      version="1.1"
      viewBox="0 0 500 500"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        clipRule="evenodd"
        d="M31.949,413.538c77.136,34.977,168.711,54.513,258.935,54.513  c29.253,0,58.055-1.364,86.134-5.998c10.985-1.819,25.253-5.908,31.427-10.626c8.636-6.637,14.18-17.08,14.18-28.803  c0-10.805-4.718-20.621-12.261-27.258h3.175c20.079,0,36.341-16.263,36.341-36.34c0-20.079-16.262-36.342-36.341-36.342h18.173  c20.078,0,36.34-16.266,36.34-36.344c0-20.079-16.262-36.341-36.34-36.341c20.078,0,36.34-16.264,36.34-36.342  c0-20.079-16.262-36.341-36.34-36.341H268.723c-12.549,0-22.715-10.177-22.715-22.715l11.435-75.505  c1.094-3.442,1.644-7.08,1.644-10.807c0-20.077-16.264-36.34-36.342-36.34c-16.804,0-30.984,11.445-35.074,26.891l-1.544,5.182  C162.782,138.338,109.903,206.214,31.949,250V413.538z"
        fill="#e30611"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default LikeIcon;
